<template>
    <div class="agentManage">
        <div class="searchForm_box">
            <el-form
                    :inline="true"
                    size="small"
                    :model="searchForm"
                    class="demo-form-inline"
            >
                <el-form-item>
                    <el-input
                            placeholder="請輸入內容"
                            v-model="searchForm.searchTxt"
                            class="input-with-select">
                        <el-select
                                v-model="searchForm.searchChoose"
                                slot="prepend"
                                placeholder="請選擇"
                                style="width: 130px">
                            <el-option label="商品條碼" value="goods_no"></el-option>
<!--                            <el-option label="商品名稱" value="goods_name"></el-option>-->
                        </el-select>
                    </el-input>
                </el-form-item>
                <el-form-item label="代理商">
                    <el-select v-model="agent_id" @change="getShop">
                        <el-option
                                v-for="item in agentList"
                                :key="item.agent_id"
                                :label="item.agent_name"
                                :value="item.agent_id">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="店鋪">
                    <el-select v-model="shop_id">
                        <el-option
                                v-for="item in shopList"
                                :key="item.shop_id"
                                :label="item.shop_name"
                                :value="item.shop_id">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="處理狀態">
                    <el-select v-model="is_solve">
                        <el-option
                                v-for="item in solve"
                                :key="item.is_solve"
                                :label="item.solve_name"
                                :value="item.is_solve">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="創建時間">
                    <el-date-picker
                            v-model="daterange"
                            style="width: 240px"
                            value-format="yyyy-MM-dd"
                            type="daterange"
                            range-separator="-"
                            start-placeholder="開始日期"
                            end-placeholder="結束日期"></el-date-picker>
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" @click="search">搜索</el-button>
                </el-form-item>
            </el-form>
        </div>
        <el-table :data="list" border class="table" v-loading="tableLoading">
            <!--            <el-table-column width="100" label="ID" prop="id"></el-table-column>-->
            <el-table-column label="商品圖片" prop="img" width="90">
                <template slot-scope="scope">
                    <el-image style="width: 50px; height: 50px" :src="scope.row.img != '' && scope.row.img != null ? scope.row.img : require('@/assets/image/default.jpg')" fit="contain">
                    </el-image>
                </template>
            </el-table-column>
            <el-table-column width="400" label="商品名稱" prop="goods_name"></el-table-column>
            <el-table-column width="200"  label="條形碼" prop="goods_no"></el-table-column>
            <el-table-column label="店鋪" prop="shop_name"></el-table-column>
            <el-table-column label="進價" prop="cost_price"></el-table-column>
            <el-table-column label="丟失數量" prop="change_num"></el-table-column>
            <el-table-column width="200" label="提交時間" prop="create_time"></el-table-column>
            <el-table-column label="處理狀態" prop="is_solve">
                <template slot-scope="scope">
                    <el-tag v-if="scope.row.is_solve=='1'" style="padding:0px 4px;line-height: 20px;height: 20px" type="success">已處理</el-tag>
                    <el-tag v-if="scope.row.is_solve=='0'" style="padding:0px 4px;line-height: 20px;height: 20px" type="danger">未處理</el-tag>
                </template>
            </el-table-column>
            <el-table-column label="操作" align="center" class-name="small-padding fixed-width" width="100">
                <template slot-scope="scope">
                    <el-button size="mini" icon="el-icon-edit" type="text" @click="edit(scope.row)" > 處理 </el-button>
                </template>
            </el-table-column>
        </el-table>
        <!-- 分頁 -->
        <el-pagination
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                :current-page="page"
                :page-sizes="[10, 20, 30, 50]"
                :page-size="pageSize"
                layout="total, sizes, prev, pager, next, jumper"
                :total="total"
        >
        </el-pagination>


        <!-- 處理 -->
        <el-dialog
                :title="title + '處理'"
                :visible.sync="visible"
                width="600px"
        >
            <el-form
                    ref="form"
                    :model="form"
                    label-width="100px"
                    size="small"
            >
                <el-row>

                    <el-col :span="24">
                        <el-form-item label="菜單模塊" prop="mold_type">
                            <el-radio-group v-model="form.is_solve">
                                <el-radio :label="0">未處理</el-radio>
                                <el-radio :label="1">已處理</el-radio>
                            </el-radio-group>
                        </el-form-item>
                    </el-col>


                    <el-col :span="24" >
                        <el-form-item prop="path">
                            <span slot="label"> 備註 </span>
                            <el-input  type="textarea" :rows="2" v-model="form.remake" placeholder="請輸入備註" />
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button size="small" type="primary" @click="submit"
                >提交</el-button
                >
                <el-button size="small" @click="reset">重置</el-button>
              </span>
        </el-dialog>
    </div>
</template>

<script>
    export default {
        name: "agentManage",

        data() {
            return {
                // 表格loading
                tableLoading: false,
                searchForm: {
                    searchTxt: "",
                    searchChoose: 'goods_no',
                    goods_name: "",
                    goods_no: "",
                    agent_id: "",
                    shop_id: "",
                },
                page: 1,
                pageSize: 50,
                total: 0,
                list: [],
                agentList: [],
                shopList: [],
                agent_id: '',
                shop_id: '',
                title:'',
                daterange:[],
                form:{
                    is_solve:0,
                    remake:'',
                    stock_id:0,
                },
                solve:[{
                    is_solve:0,
                    solve_name:'未處理',
                },{
                    is_solve:1,
                    solve_name:'已處理',
                }],
                is_solve:'',
                visible:false,
            };
        },
        created() {
            this.daterange = this.timeDefault
            this.getList();
            this.getAgent();

        },
        mounted() {
        },
        computed: {
            timeDefault() {
                let date = new Date()
                // 通過時間戳計算
                let defalutStartTime = date.getTime() - 7 * 24 * 3600 * 1000 // 轉化為時間戳
                let defalutEndTime = date.getTime()
                let startDateNs = new Date(defalutStartTime)
                let endDateNs = new Date(defalutEndTime)
                // 月，日 不夠10補0
                defalutStartTime = startDateNs.getFullYear() + '-' + ((startDateNs.getMonth() + 1) >= 10 ? (startDateNs.getMonth() + 1) : '0' + (startDateNs.getMonth() + 1)) + '-' + (startDateNs.getDate() >= 10 ? startDateNs.getDate() : '0' + startDateNs.getDate())
                defalutEndTime = endDateNs.getFullYear() + '-' + ((endDateNs.getMonth() + 1) >= 10 ? (endDateNs.getMonth() + 1) : '0' + (endDateNs.getMonth() + 1)) + '-' + (endDateNs.getDate() >= 10 ? endDateNs.getDate() : '0' + endDateNs.getDate())
                return [defalutStartTime, defalutEndTime]
            }
        },
        methods: {
            search(){
                this.page = 1;
                this.getList();
            },
            getList() {
                this.tableLoading = true
                let params = {
                    page: this.page,
                    pageSize: this.pageSize,
                    goods_no:
                        this.searchForm.searchChoose == "goods_no" ? this.searchForm.searchTxt : "",
                    goods_name:
                        this.searchForm.searchChoose == "goods_name" ? this.searchForm.searchTxt : "",
                    shop_id:this.shop_id,
                    agent_id:this.agent_id,
                    is_solve:this.is_solve,
                    start_time: this.daterange[0],
                    end_time: this.daterange[1],
                }
                this.$http('/admin/astray/list', params, 'post').then((res) => {
                    this.tableLoading = false
                    this.list = res.data.list;
                    this.total = res.data.count;
                }).catch(()=>{
                    this.tableLoading = false
                })
            },
            getAgent() {
                this.$http('/admin/agent/list', {
                    page: 1,
                    pageSize: 99999,
                }, 'post')
                    .then((res) => {
                        if (res.code == 200) {
                            this.agentList = res.data.list;
                        } else {
                            this.$message({
                                type: "error",
                                message: res.data.msg,
                            });
                        }
                        this.getShop(res.data.agent_id,res.data.shop_id);
                    })
            },
            getShop(agentId,shop_id = '') {
                this.$http('/admin/shop/list', {
                    page: 1,
                    pageSize: 99999,
                    agent_id: agentId,
                }, 'post')
                    .then((res) => {
                        this.shopList = res.data.list;
                        this.shop_id = shop_id;
                    })
            },
            edit(val){
                this.visible = true;
                this.form.is_solve = val.is_solve
                this.form.remake = val.remake
                this.form.stock_id = val.stock_id
                console.log(val)
            },
            reset(){
                this.form = {
                    is_solve:0,
                    remake:'',
                };
            },
            submit(){
                this.$http('/admin/astray/edit', {
                    stock_id: this.form.stock_id,
                    remake: this.form.remake,
                    is_solve: this.form.is_solve,
                }, 'post')
                    .then((res) => {
                        if (res.code == 200) {
                            this.$message.success("修改成功");
                            this.visible = false;
                            this.getList();
                        }else{
                            this.$message.error(res.msg);
                        }
                    })
            },
            // 分頁
            handleSizeChange(val) {
                this.pageSize = val;
                this.getList();
            },
            handleCurrentChange(val) {
                this.page = val;
                this.getList();
            },
        },
    };
</script>

<style lang="scss" scoped>
    @import "../../assets/scss/agentManage.scss";
</style>